import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0abc2e70&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abc2e70",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiLoaderComponent: require('/usr/src/app/components/UI/Loader/UiLoaderComponent.vue').default,UiIcon: require('/usr/src/app/components/UI/Icon/UiIcon.vue').default})
