
	export default {
		props: {
			options: {
				type: Object, default: () => {
				}
			},
		},
		methods: {
			onActive() {
				this.$emit('toggleActive', this.options);
			},
		},
	}
