
	import UiTab from './UiTab.vue'

	export default {
		components: {UiTab},
		props: {
			settings: {type: Array, default: () => []},
		},
		data() {
			return {
				key: 0,
			}
		},
		methods: {
			toggleActive(e) {
				this.$refs.tab[e.id - 1].$el.scrollIntoView({inline: "start", block: 'nearest'});
				this.$emit('toggleActive', e);
				this.key++;
			},
		},
	}
