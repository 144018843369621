
	import UiButton from "@/components/ui-kit/buttons/Button.vue";
	import ButtonLink from "@/components/ui-kit/buttons/ButtonLink.vue";
	import Card from "@/components/ui-kit/Card.vue";
	import {mapGetters} from "vuex";
	import gtmButtonClick from '@/mixins/gtm-button-click';
	import UiTabs from "@/components/UI/Tabs/UiTabs";

	export default {
		name: "Groups",
		mixins: [gtmButtonClick],
		components: {UiTabs, Card, ButtonLink, UiButton},
		data() {
			return {
				isTeenagerGroupsShowed: false,
				activeTab: 1,
				tabs: this.$store.state.user.isNotB2BUser ? [
					{
						id: 1,
						title: 'Я старше 18 лет',
						isActive: true,
					},
					{
						id: 2,
						title: 'Я младше 18 лет',
						isActive: false,
					},
				]: [
					{
						id: 1,
						title: 'Я старше 18 лет',
						isActive: true,
					},
				]
			}
		},
		computed: {
			...mapGetters('rubricator', ['teenagersGroups', 'adultsGroups']),

			groups() {
				return this.activeTab === 1 ? this.adultsGroups : this.teenagersGroups;
			}
		},

		methods: {
			onChecked(checked) {
				this.$store.commit('rubricator/setIsTeenagerGroupsShowed', checked);
			},
			onGroupClick(group) {
				try {
					this.gtmButtonClick(
						'choise_theme',
						group.name,
						'button',
						'click',
						'clientPage'
					);
				} catch (e) {
					console.log(e);
				}

				this.$emit('onGroupClick', group);
			},

			openApplication() {
				try {
					this.gtmButtonClick(
						'choise_theme',
						'choise_anketa',
						'button',
						'click',
						'clientPage'
					);
				} catch (e) {
					console.log(e);
				}

				this.$router.push('/client/application');
			},
			toggleActive(e) {
				this.tabs.forEach((tab) => {
					if (e.id === tab.id) {
						tab.isActive = true
						this.activeTab = tab.id
					} else {
						tab.isActive = false
					}
				})

				// try {
				// 	this.gtmButtonClick(
				// 		e.id === 1 ? 'methods': 'education',
				// 		'psychologist',
				// 		'button',
				// 		'click',
				// 		'clientPage'
				// 	);
				// } catch (e) {
				// 	console.log(e);
				// }
			},
		},

		mounted() {
			const isTeenagerGroupsShowed = this.$store.state.rubricator.isTeenagerGroupsShowed;
			this.isTeenagerGroupsShowed = isTeenagerGroupsShowed;
		}
	}
