
	import {mapActions, mapGetters, mapState} from 'vuex'
	import SpecialistBlockNotFound from '@/components/Client/Specialist/SpecialistBlockNotFound.vue'
	import UiLoaderComponent from '@/components/UI/Loader/UiLoaderComponent.vue'
	import {getErrMessageText} from '@/helpers/getErrMessageText'
	import PopupError from '@/components/Client/Popup/PopupError'
	import {userTypes} from '@/store/user'
	import gtmButtonClick from '@/mixins/gtm-button-click';
	import UiButton from '@/components/ui-kit/buttons/Button';
	import UiIcon from "@/components/UI/Icon/UiIcon";
	import Badge from "@/components/ui-kit/Badge.vue";
	import PageHeader from "@/components/ui-kit/PageHeader.vue";
	import SpecialistCard from "~/components/Client/Specialist/SpecialistCard";
	import Card from "@/components/ui-kit/Card.vue";
	import ButtonLink from "@/components/ui-kit/buttons/ButtonLink";
	import Toggle from "~/components/ui-kit/form/Toggle.vue";
	import Groups from "@/components/psychologist/search/Groups.vue";
	import ApplicationBadges from "@/components/psychologist/search/ApplicationBadges.vue";
	import FilterPsychologists from "@/components/psychologist/search/FilterPsychologists.vue";
	import ManualSearchBanner from "@/components/Client/Search/ManualSearchBanner";

	export default {
		name: 'TherapistSearch',
		components: {
			ManualSearchBanner,
			FilterPsychologists,
			ApplicationBadges,
			Groups,
			Toggle,
			ButtonLink,
			Card,
			SpecialistCard,
			Badge,
			UiIcon,
			UiButton,
			SpecialistBlockNotFound,
			UiLoaderComponent,
			PopupError,
			PageHeader,
		},
		mixins: [gtmButtonClick],
		data() {
			return {
				isLoading: true,
				showGroups: false,
				isVisibleErrorPopup: false,
				actionError: '',
				searchId: '',
				filter: this.$store.state.rubricator.filter,
			}
		},
		computed: {
			...mapState('user', ['userType']),
			...mapState('auth', ['isAuth']),
			...mapState('rubricator', ['groups', 'selectedGroup', 'page', 'pageSize', 'psychologists']),
			...mapGetters('rubricator', ['isDefaultFilters']),
			isShowMoreButton() {
				return this.psychologists?.length > this.pageSize * this.page
			},
			duration() {
				return (
					this.$store.state.globalSettings.common.SESSION_DURATION_MINUTES || 0
				)
			},
			isComplete() {
				return this.$store.state.question.clientAnswers?.complete;
			},
			timeZone() {
				return this.$store.state.user.timeZone
			},
			formattedFilter() {
				const formattedFilter = {}
				Object.entries(this.filter).forEach(([key, value]) => {
					if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value)) {
						formattedFilter[key] = value
					}
				})
				if (!this.isAuth && formattedFilter.time) {
					formattedFilter.time = {
						tz: this.timeZone,
						value: formattedFilter.time
					}
				}
				return formattedFilter
			}
		},
		watch: {
			// filter() {
			// 	this.$store.commit('rubricator/setFilter', this.filter);
			//
			// 	if (this.isAuth) {
			// 		this.fetchList()
			// 	} else {
			// 		this.fetchPublicListByFilter()
			// 	}
			// },
			psychologists(value) {
				if (value.length && !this.searchId) {
					this.searchId = value[0].search_id
				}
			},
			isAuth: {
				async handler() {
					console.log('Refreshed IsAuth=', this.isAuth);
					//await this.initData();
				}
			}
		},
		methods: {
			...mapActions({
				fetchRubricator: 'rubricator/fetchRubricator',
				fetchRubricatorByFilter: 'rubricator/fetchRubricatorByFilter',
				fetchRubricatorByGroupId: 'rubricator/fetchRubricatorByGroupId',
				fetchPublicRubricatorByApplication: 'rubricator/fetchPublicRubricatorByApplication',
				fetchPublicRubricatorByFilter: 'rubricator/fetchPublicRubricatorByFilter',

				getDictionaries: 'dictionary/getDictionaries',
				fetchGroups: 'rubricator/fetchGroups',
				fetchGlobalSettings: 'globalSettings/fetchGlobalSettings',
				storeGetClientAnswer: 'question/getClientAnswer',
				storeSetCookiesAuth: 'auth/setCookiesAuth',
			}),
			changeSearchParams() {
				this.gtmButtonClick(
					'fill_form',
					'clientmain',
					'button',
					'click',
					'clientPage',
					this.gtmUserPage
				);

				this.$router.push('/client/application')
			},
			async fetchList() {
				this.isLoading = true
				try {
					await this.fetchGlobalSettings()
					const force = Boolean(this.$route.query.force) === true;
					if (force) {
						this.$router.push({query: null});
					}

					if (this.searchId && !this.isDefaultFilters) {
						await this.fetchRubricatorByFilter({filter: this.formattedFilter, idSearch: this.searchId})
					} else {
						await this.fetchRubricator({force});
						if(this.psychologists && this.psychologists.length) {
							this.searchId = this.psychologists[0].search_id
						}
					}
				} catch (err) {
					if (this.$sentry) {
						this.$sentry.captureException(err)
					}

					const message = err.response?.data?.error_message
					this.actionError = getErrMessageText(message)
					this.isVisibleErrorPopup = true
				}
				this.isLoading = false
			},
			async fetchPublicListByFilter() {
				this.isLoading = true
				try {
					if (this.searchId) {
						await this.fetchPublicRubricatorByFilter({filter: this.formattedFilter, idSearch: this.searchId})
					}
				} catch (err) {
					if (this.$sentry) {
						this.$sentry.captureException(err)
					}

					const message = err.response?.data?.error_message
					this.actionError = getErrMessageText(message)
					this.isVisibleErrorPopup = true
				}
				this.isLoading = false
			},
			async fetchPsychologistByGroupId(group) {
				try {
					this.isLoading = true;
					this.showGroups = false;
					await this.fetchRubricatorByGroupId({group: group, is_public: !this.isAuth});
				} catch (e) {
					if (this.$sentry) {
						this.$sentry.captureException(e)
					}

					console.log(e)
				} finally {
					this.isLoading = false;
				}
			},
			showMore() {
				try {
					this.gtmButtonClick(
						'card_psy',
						'show_more_psy',
						'button',
						'click',
						'clientPage',
						this.gtmUserPage
					);
				} catch (e) {
					console.log(e);
				}

				this.$store.commit('rubricator/setPage', this.page + 1);
			},
			clearSearch() {
				this.showGroups = true;
				this.$store.commit('rubricator/setSelectedGroup', null);
				this.$store.commit('rubricator/setDefaultFilter');

				localStorage.removeItem('application');

				this.filter = this.$store.state.rubricator.filter;
				this.$router.push({query: null});

				try {
					this.gtmButtonClick(
						'worry_you',
						'change',
						'button',
						'click',
						'clientPage'
					);
				} catch (e) {
					console.log(e);
				}
			},
			applyFilters() {
				this.$store.commit('rubricator/setFilter', this.filter);

				if (this.isAuth) {
					this.fetchList()
				} else {
					this.fetchPublicListByFilter()
				}
			},
			async initData() {
				this.searchId = this.psychologists && this.psychologists.length ? this.psychologists[0].search_id : '';
				const {group_id} = this.$route.query;

				this.$store.commit('rubricator/setDefaultFilter');
				this.filter = this.$store.state.rubricator.filter;

				if (group_id) {
					const group = this.groups.find(group => group.id === group_id);
					if (group) {
						this.$store.commit('rubricator/setSelectedGroup', group);
						await this.fetchPsychologistByGroupId(group);

						localStorage.removeItem('application');

						return;
					}
				}

				if (this.isAuth) {
					await this.storeGetClientAnswer(!this.isAuth);

					if (this.isComplete) {
						await this.fetchList();
					} else {
						this.showGroups = true;
					}
				} else {
					let lsApplication = null
					if (localStorage.getItem('application')) {
						lsApplication = JSON.parse(localStorage.getItem('application'))
						this.$store.commit('question/setClientAnswers', {complete: true, results: lsApplication});
					}

					if (!lsApplication) {
						const resp = await this.storeGetClientAnswer(true);
						lsApplication = resp.results;
					}

					if (lsApplication && lsApplication.length) {
						await this.fetchPublicRubricatorByApplication(lsApplication)
					} else {
						this.showGroups = true;
					}
				}

				this.isLoading = false
			}
		},
		async beforeMount() {
			if (this.userType === userTypes.psychologist) {
				await this.$router.push({path: '/search/psychologist'})
			}
		},
		async mounted() {
			const {refresh} = this.$route.query;

			if (this.psychologists.length && (refresh === false || refresh === 'false')) {
				const query = Object.assign({}, this.$route.query);
				delete query.refresh;
				this.$router.replace({query});
				this.searchId = this.psychologists && this.psychologists.length ? this.psychologists[0].search_id : '';
				// this.$router.replace({'query':  null});
				this.isLoading = false
				return
			}
			try {
				this.isLoading = true;
				await this.storeSetCookiesAuth()
				await this.fetchGroups();
				await this.getDictionaries(this.isAuth ? 'client': 'public');
				await this.initData();
			} catch (e) {
				console.log(e);

				if (this.$sentry) {
					this.$sentry.captureException(e)
				}

			} finally {
				this.isLoading = false;
			}
		},
	}
