
	import {mapState} from "vuex";

	import Badge from "@/components/ui-kit/Badge.vue";
	import UiButton from "@/components/ui-kit/buttons/Button.vue";
	import UiIcon from "@/components/UI/Icon/UiIcon.vue";
	import ButtonLink from "@/components/ui-kit/buttons/ButtonLink";

	import tags from "@/mixins/tags";

	export default {
		name: "ApplicationBadges",
		components: {ButtonLink, Badge, UiButton, UiIcon},
		mixins: [tags],
		computed: {
			clientAnswers() {
				return this.$store.state.question.clientAnswers?.results || [];
			},
			answerBadges() {
				const answers = [];

				this.clientAnswers.forEach(item => {
					if (item.question_slug === 'preferred_issues') {
						if (item.multiple) {
							answers.push(...item.answer.map(answer => answer.name));
						} else {
							answers.push(item.answer.name);
						}
					}
				})

				return answers;
			},
			toggleButtonLabel () {
				let label = '';

				if(this.isTagsShown)
					return 'Скрыть';

				const hiddenBadgesCount = this.answerBadges.length - this.maxTagsAmount;

				label = `+ ${hiddenBadgesCount} тем${ hiddenBadgesCount === 1? 'a': hiddenBadgesCount < 5 ? 'ы': ''}`

				return label;
			}
		},
		mounted() {
			this.updateTagsLayout();
		}
	}
