/**
 * @param text { string }
 * @param errPayload { string | object }
 * @return { string }
 */
import { getFormattedFieldName } from '@/helpers/getFormattedFieldName'

export const getErrMessageText = (text, errPayload = '') => {
  if (!text || typeof text !== 'string') return text

  let settings = {}

  if (process.browser) {
    settings = window?.$nuxt?.$store?.state?.globalSettings?.common || {}
  }

  if (text.includes('password invalid')) {
    return 'Неверный формат пароля.'
  }

  if (text.includes('session cancellation time has expired')) {
    const p =
      errPayload.TIME_IN_HOURS_BEFORE_SESSION_CANCELLATION ||
      settings.TIME_IN_HOURS_BEFORE_SESSION_CANCELLATION ||
      1
    return `До сессии осталось менее ${p} ч, отмена сессии невозможна.`
  }

  if (
    text.includes('session with this psychologist already exists on this day')
  ) {
    return 'Вы уже записаны на этот день.'
  }

  if (text.includes('phone number is already in use')) {
    return 'Телефон уже используется.'
  }

  if (text.includes('earlier')) {
    const hours = Number(text.replace(/\D/g, ''))
    if (hours) {
      return `Нельзя записаться или перенести сессию на слот до которого осталось менее ${hours} ${
        hours % 10 === 1 ? 'часа' : 'часов'
      }. Пожалуйста, выберите другой день или время.`
    } else {
      return 'Нельзя записаться или перенести сессию на слот до которого осталось менее часа. Пожалуйста, выберите другой день или время.'
    }
  }
  if (text.includes('Slot is busy')) {
    return 'Слот занят.'
  }

  if (text.includes('later')) {
    // example: Dates later than in 14 days are not allowed: 2022-08-13 19:30:00+00:00
    const days =
      errPayload.MAX_SESSION_DISTANCE_DAYS ||
      settings.MAX_SESSION_DISTANCE_DAYS ||
      1
    const word = days % 10 === 1 ? 'дня' : 'дней'
    return `Нельзя установить дату позднее ${days} ${word} от текущей.`
  }

  // Weekly calendar sessions amount exceeded
  if (text.includes('Weekly calendar')) {
    return 'Превышено количество слотов в неделю.'
  }

  if (text.includes('Weekly amount allowed')) {
    const count = text.replace(/\D/gi, '')
    return `Превышено количество слотов в неделю: ${count}`
  }

  if (text.includes('session rescheduling time has expired') || text.includes('Session is not reschedulable')) {
    const p =
      errPayload.TIME_IN_HOURS_BEFORE_SESSION_RESCHEDULING ||
      settings.TIME_IN_HOURS_BEFORE_SESSION_RESCHEDULING ||
      1
    return `Вы не можете перенести сессию до которой осталось менее ${p} ${
      p % 10 === 1 ? 'часа' : 'часов'
    }.`
  }

  // Dates with interval less than 75 minutes are not allowed
  if (text.includes('interval less')) {
    const p =
      errPayload.MIN_REST_INTERVAL_MINUTES ||
      settings.MIN_REST_INTERVAL_MINUTES ||
      '60'
    return `Невозможно поставить слот. Проверьте, что вы не попадаете на другую сессию и перерыв между следующей и прошлой сессией не меньше ${p} минут.`
  }

  // Daily calendar sessions amount exceeded
  if (text.includes('Daily calendar')) {
    const obj =
      errPayload.MAX_SESSIONS_COUNT_DAILY ||
      settings.MAX_SESSIONS_COUNT_DAILY ||
      '5'

    return `Превышено количество слотов в день, максимальное количество слотов - ${obj}. Возможно, у вас есть слоты в других таймзонах.`
  }

  if (text.includes('invalid password')) {
    return 'Неверный текущий пароль.'
  }

  if (text.includes("It doesn't exist")) {
    return 'Выбранный слот недоступен или был удален.'
  }

  if (text.includes('Session not exists')) {
    return 'Сессия не найдена.'
  }

  if (text.includes('email is already in use')) {
    return 'Почтовый адрес уже используется.'
  }

  if (text.includes('Exceed the maximum size (px)')) {
    return 'Превышение максимального размера (px).'
  }

  if (text.includes('The width of the image must be less than 1000 px')) {
    return 'Ширина изображения должна быть менее 1000 px.'
  }

  if (text.includes('The height of the image must be less than 1000 px')) {
    return 'Высота изображения должна быть менее 1000 px.'
  }

  if (text.includes('Exceed the maximum size')) {
    return 'Файл слишком большого размера.'
  }

  if (text.includes('Wrong content-type')) {
    return 'Неверный формат файла.'
  }

  if (text.includes('Ошибка валидации параметров')) {
    return 'Ошибка валидации параметров.'
  }

  if (text.includes('field contains non-Cyrillic symbols')) {
    return 'Имя должно состоять из букв кириллического алфавита. Допустимы символы пробела и тире.'
  }

  if (text.includes('убедитесь, что это значение имеет как минимум 2 букв')) {
    return 'Имя должно содержать не менее 2-х букв кириллического алфавита.'
  }

  if (text.includes('Daily amount allowed')) {
    const p =
      errPayload.MAX_SESSIONS_COUNT_DAILY ||
      settings.MAX_SESSIONS_COUNT_DAILY ||
      '5'
    return `Максимальное допустимое количество слотов в день – ${p}. Убедитесь, что у вас отсутствуют проставленные слоты в других временных зонах.`
  }

  if (text.includes('incorrect date of birth')) {
    return 'Некорректная дата рождения.'
  }

  if (text.includes('Phone Number Invalid.')) {
    return 'Некорректный номер телефона.'
  }

  if (text.includes('missing')) {
    return 'Запись к выбранному психологу невозможна. Обновите страницу.'
  }

  if (text.includes('Client has already made order')) {
    return 'На слот уже записался клиент. Перенос или отмена возможны со страницы сессии.'
  }

  if (text.includes('Safe:')) {
    const p =
      errPayload.MIN_REST_INTERVAL_MINUTES ||
      settings.MIN_REST_INTERVAL_MINUTES ||
      '60'
    return `Невозможно поставить слот, т.к. между предыдущей и следующей сессией менее ${p} минут.`
  }

  if (
    (text.includes('убедитесь, что это значение имеет как минимум') ||
      text.includes('убедитесь, что это значение имеет максимум')) &&
    errPayload.field
  ) {
    return `Поле «${getFormattedFieldName(errPayload.field)}»: ${text}.`
  }

  if (
    text.includes("The values in the fields shouldn't overlap") &&
    errPayload.field === 'preferred_issues, no_work_issues'
  ) {
    return 'Значения в полях предпочтительные темы и темы с которыми не работаю, не должны совпадать'
  }

  if (
    text.includes(
      'Поле должно состоять минимум из двух символов и включать только кириллицу, пробелы и тире'
    )
  ) {
    const { field } = errPayload
    let fieldName = ''

    switch (field) {
      case 'name':
        fieldName = 'Имя'
        break
      case 'surname':
        fieldName = 'Фамилия'
        break
      case 'city':
        fieldName = 'Город'
        break
      default:
        break
    }

    return `Поле ${
      fieldName ? `«${fieldName}» ` : ''
    }должно состоять минимум из двух символов и включать только кириллицу, пробелы и тире`
  }

  if (
    text.includes('Trying to create without files') &&
    errPayload.field === 'university, __root__'
  ) {
    return 'Проверьте загруженность файлов дипломов в блоке Образование'
  }

  if (
    text.includes('Trying to create without files') &&
    errPayload.field === 'qualification, __root__'
  ) {
    return 'Проверьте загруженность файлов дипломов в блоке Квалификация'
  }

  return text
}
