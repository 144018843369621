
	import UiIcon from '@/components/UI/Icon/UiIcon'
	import UiButton from '@/components/ui-kit/buttons/Button'
	import gtmButtonClick from '@/mixins/gtm-button-click'
	import Card from "@/components/ui-kit/Card.vue";

	export default {
		components: {UiButton, UiIcon, Card},
		mixins: [gtmButtonClick],
		methods: {
			onChangeParams() {
				this.gtmButtonClick(
					'Психологи не найдены / Изменить параметры',
					'По вашему запросу подобрано',
					'button',
					'click',
					this.gtmUserPage
				)

				this.$emit('changeSearchParams');
				// this.$router.push('/client/application')
			},
		},
	}
