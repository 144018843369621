export default {
	data() {
		return {
			maxTagsAmount: 0,
			isTagsShown: false,
			tagsWidths: [],
			tagsSettings: {
				gap: 8
			}
		}
	},
	methods: {
		toggleTags() {
			this.isTagsShown = !this.isTagsShown;
		},
		updateTagsLayout(isTagsChanged = false) {
			const container = this.$refs.tags;
			if (container) {
				const width = container.offsetWidth - 340;
				let widths = [];

				if (isTagsChanged || !this.tagsWidths.length) {
					const tags = container.children;
					for (const tag of tags) {
						widths.push(tag.offsetWidth);
					}
					this.tagsWidths = JSON.parse(JSON.stringify(widths));
				} else {
					widths = [...this.tagsWidths];
				}
				const { gap } = this.tagsSettings;

				let lineWidth = 0;
				let amount = 0;

				let i = 0;

				while (lineWidth + widths[i] + gap < width) {
					lineWidth += widths[i] + gap;
					amount++;
					i++;
				}

				this.maxTagsAmount = amount;
			}
		},
	},
	mounted() {
		window.addEventListener('resize', () => this.updateTagsLayout(false));
	},
	beforeDestroy() {
		window.removeEventListener('resize', () => this.updateTagsLayout(false));
	}
}
