export const getFormattedFieldName = (code) => {
	const map = {
		name: 'Имя',
		surname: 'Фамилия',
		date_of_birth: 'Дата рождения',
		city: 'Город',
		gender: 'Пол',
		photo: 'Фотография',
		beginning_of_practice: 'Год начала практики',
		practice_hours: 'Часы практики',
		supervision_hours: 'Часы супервизии',
		work_experience: 'Опыт работы',
		work_experience_description: 'Описание опыта работы',
		about: 'О себе',
		short_about: 'Коротко о себе',
		therapy_methods: 'Методы терапии',
		age_category: 'Возрастная категория',
		preferred_issues: 'Предпочтительные',
		no_work_issues: 'Не работаю',
		article_image: 'Обложка для статьи',
		description: 'Описание',
		graduation_year: 'Год окончания обучения',
		specialization: 'Специализация',
		current_password: 'Текущий пароль',
	};

	return map[code] || code;
};
